/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

import { Parallax } from "react-parallax";
import { motion } from 'framer-motion';

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/scrimmage-bg-placeholder.jpg";

const infoVariants = {
  offscreen: {
    opacity: 0,
    x: 100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 1,
    },
  },
};

const downloadVariants = {
  offscreen: {
    opacity: 0,
    x: -100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    // rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 1,
    },
  },
};

function Home() {
  return (
    <>
      <DefaultNavbar
        brand="Scrimmage"
        routes={routes}
        action={{
          type: "internal",
          route: "",
          label: "Coming Soon",
          color: "white",
        }}
        // relative
        sticky
      />
      <Parallax bgImage={bgImage} strength={500}>
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            // backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={7}
              justifyContent="center"
              mx="auto"
            >
              <MKTypography
                variant="h1"
                color="white"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Scrimmage
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Setting up game time shouldn't be hard. Scrimmage takes out the
                complication so you can focus on what you love most...
              </MKTypography>
              <MKTypography
                variant="h3"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                the game.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Parallax>
      {/* <Card
        sx={{
          mt: -30,
          height: 150,
          borderRadius: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0), // backdropFilter: "saturate(200%) blur(30px)"
        }}
      >
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ backdropFilter: "saturate(200%) blur(30px)" }}
        >
          <path
            d="M0.00,49.98 C237.87,109.05 240.12,-4.42 500.00,49.98 L504.79,151.48 L0.00,150.00 Z"
            style={{
              stroke: 'none', 
              fill: "rgba(255,255,255,0.8"
            }}
          ></path>
        </svg>
      </Card> */}
      {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        // viewport={{ amount: 0.8 }}
      > */}
        <Card
          sx={{
            p: 2,
            // mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            borderRadius: 0,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Counters />
          <Container sx={{ overflow: 'hidden' }}>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={infoVariants}
            >
              <Information />
            </motion.div>
          </Container>
          <Testimonials />
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={downloadVariants}
          >
            <Download />
          </motion.div>
        </Card>
      {/* </motion.div> */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
