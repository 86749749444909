/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// @mui icons
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import LanguageIcon from "@mui/icons-material/Language";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Parallax } from "react-parallax";

// Images
import bgImage from "assets/images/sports2-placeholder.jpg";

function Download() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      
        <MKBox
          variant="gradient"
          bgColor="dark"
          position="relative"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          {/* <MKBox
            component="img"
            src={bgImage}
            alt="pattern-lines"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            zIndex={1}
            opacity={0.2}
          /> */}
          <Parallax bgImage={bgImage} strength={600} bgImageStyle={{ opacity: 0.2 }}>
            <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
              <Grid
                container
                item
                xs={12}
                md={7}
                justifyContent="center"
                mx="auto"
                textAlign="center"
              >
                <MKTypography variant="h2" color="white" mb={1}>
                  Ready to Scrimmage?
                </MKTypography>
                <MKTypography variant="body2" color="white" mb={6}>
                  Download Scrimmage on your favorite platform now and get
                  scrimmaging!
                </MKTypography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={7}
                justifyContent="center"
                mx="auto"
                textAlign="center"
              >
                <MKButton
                  variant="gradient"
                  color="success"
                  size="large"
                  component="a"
                  href=""
                  onClick={(e) => e.preventDefault()}
                  sx={{ mb: 2, mx: 2 }}
                >
                  Android
                </MKButton>
                <MKButton
                  variant="gradient"
                  color="white"
                  size="large"
                  component="a"
                  href=""
                  onClick={(e) => e.preventDefault()}
                  sx={{ mb: 2, mx: 2 }}
                >
                  iOS
                </MKButton>
                <MKButton
                  variant="gradient"
                  color="info"
                  size="large"
                  component="a"
                  href=""
                  onClick={(e) => e.preventDefault()}
                  sx={{ mb: 2, mx: 2 }}
                >
                  Web (Coming Soon!)
                </MKButton>
              </Grid>
            </Container>
          </Parallax>
        </MKBox>
      <Container>
        <Grid container item xs={6} mx="auto" justifyContent="center">
          <MKBox textAlign="center">
            <MKTypography variant="h3" mt={6} mb={3}>
              Soon to be available on these platforms
            </MKTypography>
            <Grid container justifyContent="center">
              <Grid item xs={6} lg={2}>
                <Tooltip title="Android">
                  <MKBox
                    component="a"
                    // href=""
                    // target="_blank"
                  >
                    {/* <MKBox
                      component="img"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/bootstrap5.jpg"
                      width="100%"
                    /> */}
                    <AndroidIcon fontSize="large" />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Tooltip title="Comming soon">
                  <MKBox
                    opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    {/* <MKBox
                      component="img"
                      src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/icon-tailwind.jpg"
                      width="100%"
                    /> */}
                    <AppleIcon fontSize="large" />
                  </MKBox>
                </Tooltip>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Download;
